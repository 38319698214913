import React from "react";
import Layout from "../../components/Layout/Layout";
import SEO from "../../components/Seo";
import {Box, Button, Container, Image, Lead, T2} from "../../styles/Styles";
import hero from "../../img/hero-transportation-ind.png";
import Clients from "../../components/Homepage/Clients";

const benefits = [
    `リアルタイムで、<nobr>道路レベルのデータストリーム</nobr>`,
    "カスタマイズされた安全アラート",
    "一貫した適応範囲",
    "システムの互換性"
];

export default () => {
    return (
        <Layout>
            <SEO
                title="交通 | tomorrow.io"
                image={hero}
            />

            <Container>
                <Box display="flex" flexWrap="wrap" pb={4}>
                    <Box
                        width={[1, 1 / 2]}
                        pr={[0, 4]}
                        pt={5}
                    >
                        <T2 as="h1" mb={3}>
                            輸送と物流
                        </T2>
                        <Lead mb={4}>
                            ルートに天候ハザード？tomorrow.ioだけがお知らせできます。
                        </Lead>
                        <Button
                            as="a"
                            display={["block", "inline-block"]}
                            mb={2}
                            href="https://www.tomorrow.io/get-a-demo-of-hypercast/"
                        >
                            tomorrow.ioデモ取得
                        </Button>{" "}
                        <Button
                            outline="1"
                            as="a"
                            display={["block", "inline-block"]}
                            mb={2}
                            href="https://www.tomorrow.io/api-signup/"
                        >
                            APIキー取得
                        </Button>

                    </Box>
                    <Box width={[1, 1 / 2]} pt={2} px={3} textAlign="center">
                        <Image
                            maxHeight="380px"
                            src={hero}
                            alt=""
                        />
                    </Box>
                </Box>
            </Container>

            <section className="c-section py-5">
                <div className="container">
                    <Clients/>
                </div>
            </section>

            <section className="c-section">
                <h3 className="h-3 text-center">ベネフィット：</h3>
                <div className="container">
                    <div className="col-md-8 mx-auto">
                    <div className="row justify-content-center">

                            {benefits.map((item, index) => (
                                <div
                                    key={index}
                                    className="col-md-5 mt-5 pr-0 pr-xl-4"
                                >
                                    <div className="item-bullet" dangerouslySetInnerHTML={{__html: item}}/>
                                </div>
                            ))}
                    </div>
                    </div>
                </div>
            </section>


            <section className="c-section pb-0">
                <div className="container">
                    <div className="row justify-content-center text-dark">
                        <div className="col-lg-3">
                            <h5 className="fw-700 lh-md">
                                もっとも正確で、常に利用可能なデータによって危険な不意打ちの事象を回避
                            </h5>
                            <div className="divider-blue my-5 w-25"/>
                        </div>
                        <div className="col-lg-5 offset-md-1">
                            <div className="lead fs-lg mb-5 block-fluid">
                                <p>
                                    MicroWeather
                                    APIデータを利用して、不必要な天候関連の非効率事象や遅延事象を減らしましょう。その代わりに、ドライバーや車両、カーゴの保護を強化できます。顧客もきっと感謝するはずです。
                                </p>

                                <p>
                                tomorrow.ioの天気予報は、ドライバーがオンタイムで到着するのをサポートし、オペレーションセンターが厳しい天候状況に対し、自発的に行動するのを促します。また費用を削減しつつ、安全性を高めることができます。
                                </p>

                                <p>
                                    私たちは、レーダーの倍の信頼がおける、分単位の短期間予測を生み出します。MicroWeatherデータの利用で、リアルタイムの需要の予測をアシスト。tomorrow.ioは、降水量の分類や、危険な気象現象が起こる、ずいぶん前にアラートを発信します。
                                </p>

                                <Button
                                    as="a"
                                    display={["block", "inline-block"]}
                                    mb={2}
                                    id="industry-transportation-link-to-form-2"
                                    href="https://www.tomorrow.io/get-a-demo-of-hypercast/"
                                >
                                    デモを入手
                                </Button>

                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </Layout>
    );
};